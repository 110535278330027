import { BreakoutType } from '../types/invitee';

export function toKebab(value: string): string {
	return value
		.toLowerCase()
		.replace(/\s/g, '-')
		.replace(/[^a-z0-9-]/g, '');
}

export function humanizeDuration(seconds: number): string {
	const hours = Math.floor(seconds / 3600);
	seconds = seconds % 3600;
	const minutes = Math.floor(seconds / 60);

	if (minutes !== 0) {
		return `${hours !== 0 ? hours + 'h' : ''}${minutes}m`;
	}
	return `${hours}h`;
}

export function getRegistrationBreakoutName(breakout: BreakoutType): string {
	if (breakout.invitees_limit < 1 || breakout.open_choice) {
		return breakout.name;
	}
	const seatsLeftCount = breakout.invitees_limit - breakout.invitees_count;
	return `${breakout.name} - ${seatsLeftCount} Seat${seatsLeftCount !== 1 ? 's' : ''} left`;
}
