import React, {useState, createContext} from "react";

export interface AvatarContextInterface {
	avatar?: string;
	setAvatar: (avatar?: string) => void
}

export const AvatarContext = createContext<AvatarContextInterface>(null);

const AvatarContextContainer: React.FC = ({children}) => {
	const [avatar, setAvatar] = useState<string>(null);
	return (
		<AvatarContext.Provider
			value={{
				avatar,
				setAvatar
			}}
		>
			{children}
		</AvatarContext.Provider>
	);
};

export default AvatarContextContainer;