import ReactPlayer from 'react-player';
import { useCallback, useContext } from 'react';
import { OpeningPageData, VideoQuestion, VideoQuestionOption, VideoQuestionType } from '../types/opening';
import { usePage } from './usePage';
import {
	getActiveQuestion,
	goToOptionTarget,
	getOptionForInvitee,
	shouldAnswerForInvitee,
} from '../helpers/interactive-video-helpers';
import { submitVideoQuestion } from '../queries/videoQuestion';
import { InteractivePlayerContext, InteractivePlayerContextInterface } from '../contexts/InteractivePlayerContext';

declare global {
	interface Window {
		player?: ReactPlayer;
	}
}

export const useInteractivePlayer = (player?: ReactPlayer) => {
	const { invitee, video_questions, video_answers } = usePage<OpeningPageData>();
	const { answers, setAnswers, playing, setPlaying, activeQuestion, setActiveQuestion } = useContext<
		InteractivePlayerContextInterface
	>(InteractivePlayerContext);

	const submitOption = useCallback((question: VideoQuestion, givenAnswer: VideoQuestionOption): void => {
		submitVideoQuestion(invitee, question, givenAnswer).then(() => {}); //.then((result) => console.log('post', result));

		goToOptionTarget(givenAnswer, player);
		setAnswers((oldAnswers) => ({
			...oldAnswers,
			[question.key]: givenAnswer.value,
		}));
		setActiveQuestion(undefined);
	}, [invitee, player, setAnswers, setActiveQuestion]);

	const onProgress = useCallback(() => {
		if (!player || !playing) {
			return;
		}

		const currentTime = player.getCurrentTime();
		const newActive = getActiveQuestion(currentTime, video_questions);

		if (newActive && newActive.type === VideoQuestionType.JUMP_TIMESTAMP) {
			goToOptionTarget(newActive.options[0], player);
		} else if (shouldAnswerForInvitee(answers, activeQuestion, newActive)) {
			const autoChoice = getOptionForInvitee(activeQuestion, video_answers);
			submitOption(activeQuestion, autoChoice);
		} else {
			setActiveQuestion(newActive);
		}
	}, [playing, activeQuestion, answers, video_questions, player, setActiveQuestion, submitOption, video_answers]);


	const onEnded = () => {
		setPlaying(false);
		const endQuestion = video_questions.find((question) => question.type === VideoQuestionType.END);
		setActiveQuestion(endQuestion);
	};

	const resetAnswers = () => {
		setAnswers({});
	};

	return {
		activeQuestion,
		answers,
		onPlay: () => setPlaying(true),
		onPause: () => setPlaying(false),
		onEnded,
		onProgress,
		submitOption,
		resetAnswers,
	};
};
