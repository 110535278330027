import { createPortal } from 'react-dom';
import React, {useEffect, useState, ReactElement, ReactNodeArray, ReactPortal, useCallback} from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { modalRoot } from '../../config';

type ModalProps = {
	children: ReactElement | ReactNodeArray | null | undefined;
	image?: string;
	isSmall?: boolean;
    canClose?: boolean;
	onClose: () => any;
	modalClass?: string;
};

const Modal: React.FC<ModalProps> = ({ children, onClose, image, isSmall = false, canClose = true, modalClass }): ReactPortal => {
	return createPortal(
		<ModalWrapper children={children} onClose={onClose} image={image} isSmall={isSmall} modalClass={modalClass} canClose={canClose} />,
		modalRoot,
	);
};

export default Modal;

const ModalWrapper: React.FC<ModalProps> = ({ children, onClose, image, isSmall, modalClass, canClose }) => {
	const [visible, setVisible] = useState<boolean>(false);


	const closeModal = useCallback((e) => {
		e.stopPropagation();

		const classList = e.currentTarget.classList;

		if (classList.contains('modal-close') || e.target === e.currentTarget) {
			setVisible(false);
			setTimeout(() => {
				onClose();
			}, 250);
		}
	}, [setVisible, onClose]);

	useEffect(() => {
		setVisible(true);

		const handleKeyUp = (e) => {
			if (canClose && (e.key === 'Escape' || e.key === 'Esc')) {
				closeModal(e);
			}
		};
		window.addEventListener('keyup', handleKeyUp);

		return () => {
			window.removeEventListener('keyup', handleKeyUp);
		};
	}, [canClose, closeModal]);

	return (
		// @ts-ignore
		<RemoveScroll className={`modal-wrapper${isSmall ? ' small' : ''} ${visible ? 'visible' : 'hidden'}`} onClick={canClose ? closeModal : null}>
			<div className={`modal ${modalClass ?? ''}`}>
                {canClose && <button onClick={closeModal} className="modal-close">
                        <svg
						className="centered"
						xmlns="http://www.w3.org/2000/svg"
						width="21.414"
						height="21.414"
						viewBox="0 0 21.414 21.414"
					>
						<path
							d="M10,10,0,20,10,10,0,0,10,10,20,0,10,10,20,20Z"
							transform="translate(0.707 0.707)"
							fill="none"
							strokeWidth="2"
						/>
					</svg>
				</button>}
				<div className="modal-body">
					{image && (
						<div className="modal-image">
							<img src={image} alt="" />
						</div>
					)}
					<div className="modal-content">{children}</div>
				</div>
			</div>
		</RemoveScroll>
	);
};
