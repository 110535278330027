import React from 'react';

type CameraIconProps = {
	width?: number,
	height?: number,
}

const CameraIcon: React.FC<CameraIconProps> = ({ width = 22.612, height = 17.797}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="icon" width={width} height={height} viewBox="0 0 22.612 17.797">
			<g transform="translate(0 0)">
				<path className="fill"
					  fill="#ffffff"
					  d="M21.859,47.573a2.431,2.431,0,0,0-1.784-.752H16.507v-.043a1.93,1.93,0,0,0-.58-1.4,1.967,1.967,0,0,0-1.4-.58H8.082a1.982,1.982,0,0,0-2,1.977v.043H2.536a2.431,2.431,0,0,0-1.784.752A2.555,2.555,0,0,0,0,49.357v10.7a2.431,2.431,0,0,0,.752,1.784,2.555,2.555,0,0,0,1.784.752H20.075a2.431,2.431,0,0,0,1.784-.752,2.555,2.555,0,0,0,.752-1.784v-10.7A2.431,2.431,0,0,0,21.859,47.573Zm-.365,12.488h-.021a1.394,1.394,0,0,1-1.4,1.4H2.536a1.394,1.394,0,0,1-1.4-1.4v-10.7a1.394,1.394,0,0,1,1.4-1.4H6.685a.578.578,0,0,0,.58-.58v-.623a.809.809,0,0,1,.838-.838H14.53a.809.809,0,0,1,.838.838v.623a.578.578,0,0,0,.58.58H20.1a1.394,1.394,0,0,1,1.4,1.4Z"
					  transform="translate(0 -44.8)"
				/>
				<path className="fill"
					  fill="#ffffff"
					  d="M117.287,130.8a5.284,5.284,0,1,0,3.74,1.548A5.3,5.3,0,0,0,117.287,130.8Zm2.923,8.232a4.151,4.151,0,0,1-5.846,0,4.109,4.109,0,0,1-1.2-2.923,4.2,4.2,0,0,1,1.2-2.923,4.109,4.109,0,0,1,2.923-1.2,4.2,4.2,0,0,1,2.923,1.2,4.109,4.109,0,0,1,1.2,2.923A4.022,4.022,0,0,1,120.211,139.032Z"
					  transform="translate(-105.982 -126.179)"
				/>
				<circle
					className="fill"
					fill="#ffffff"
					cx="1.053" cy="1.053" r="1.053"
					transform="translate(17.904 4.6)"
				/>
			</g>
		</svg>
	);
};

export default CameraIcon;
