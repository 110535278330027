import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useInteractivePlayer } from '../../hooks/useInteractivePlayer';
import ActiveQuestion from './ActiveQuestion';
import { usePage } from '../../hooks/usePage';
import { OpeningPageData } from '../../types/opening';

declare global {
	interface Window {
		pp: () => void;
	}
}

const InteractivePlayer: React.FC = () => {
	const { video_url } = usePage<OpeningPageData>();
	const wrapper = useRef<HTMLDivElement>();
	const videoElement = useRef<ReactPlayer>();
	const debugRef = useRef<() => any>();
	const [playState, setPlayState] = useState(false);
	const { onPlay, onPause, onEnded, onProgress, submitOption, activeQuestion } = useInteractivePlayer(
		videoElement.current,
	);
	const [fontSize, setFontSize] = useState<number>(15);
	const wrapperStyle = {
		'--video-font-size': `${fontSize}px`,
	} as React.CSSProperties;

	useEffect(() => {
		const calcFontSize = () => {
			if (!wrapper.current) {
				setFontSize(15);
				return;
			}

			const scale = wrapper.current.offsetWidth / 1920;
			setFontSize(Math.max(5, Math.min(18, scale * 15)));
		};

		window.addEventListener('resize', calcFontSize);
		calcFontSize();

		return () => {
			window.removeEventListener('resize', calcFontSize);
		};
	}, []);

	return (
		<>
			<div className="interactive-player video-wrapper" ref={wrapper} style={wrapperStyle}>
				<ReactPlayer
					className="react-player"
					url={video_url}
					muted={false}
					volume={1}
					controls={false}
					ref={videoElement}
					playing={playState}
					onPlay={onPlay}
					onPause={onPause}
					onEnded={onEnded}
					onProgress={() => {
						onProgress();
						if (debugRef.current) {
							debugRef.current();
						}
					}}
					width="100%"
					height="100%"
					config={{
						file: {
							attributes: {
								poster: '/images/opening/opening-poster.jpg',
							},
						},
					}}
				/>
				<div className="click-blocker" />
				{activeQuestion && (
					<ActiveQuestion
						onSubmit={submitOption}
						activeQuestion={activeQuestion}
						setPlayState={setPlayState}
					/>
				)}
				{!playState && !activeQuestion && (
					<button className="btn play-btn" onClick={() => setPlayState(true)}>
						Start your journey
					</button>
				)}
			</div>
		</>
	);
};

export default InteractivePlayer;
