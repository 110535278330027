import React, {useState, useEffect, useCallback} from 'react';
import { usePage } from '../../hooks/usePage';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import Flickity from 'react-flickity-component';
import { StreamUsePageData } from '../../types/invitee';
import { humanizeDuration } from '../../helpers/string-helpers';

dayjs.extend(isBetween);

const Timeline: React.FC<{ programOffset: number }> = ({ programOffset }) => {
	const BLOCK_WIDTH = 280;
	const BLOCK_PADDING = 20;

	const { activities } = usePage<StreamUsePageData>();
	const [sliderRef, setSliderRef] = useState(null);

	const getTimeWithOffset = useCallback((time) => {
		if (programOffset) {
			return time.add(programOffset, 'minute');
		}
		return time;
	}, [programOffset]);

	useEffect(() => {
		if (!sliderRef?.onresize) {
			return;
		}
		sliderRef.onresize();
	}, [sliderRef]);

	return (
		<div className="timeline">
			<Flickity
				flickityRef={(ref) => setSliderRef(ref)}
				className="slider"
				options={{
					initialIndex: 0,
					cellSelector: '.activity-slide',
					pageDots: false,
					contain: true,
					freeScroll: true,
				}}
			>
				{activities.map((activity, index: number) => {
					const activityStart: dayjs.Dayjs = getTimeWithOffset(dayjs(activity.start));
					const activityEnd: dayjs.Dayjs = getTimeWithOffset(dayjs(activity.end));
					const duration = humanizeDuration(activityEnd.diff(activityStart, 'second'));
					return (
						<div key={index} className="activity-slide">
							<span className="time start">{activityStart.format('H:mm')}</span>
							<span className="time end">{activityEnd.format('H:mm')}</span>
							<div
								className="activity"
								style={{ marginRight: BLOCK_PADDING, width: BLOCK_WIDTH }}
							>
								{activity.image && <img src={activity.image.thumb} alt="" />}
								<div className="details">
									<span className="name">{activity.name}</span>
									<span>{duration}</span>
								</div>
							</div>
						</div>
					);
				})}
			</Flickity>
		</div>
	);
};

export default Timeline;
