import React from 'react';

type CrossIconProps = {
	width?: number,
	height?: number,
}

const CrossIcon: React.FC<CrossIconProps> = ({width = 12, height = 12}) => {
	return (
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" className='icon'>
		<g transform="translate(-21.146 -3.146)">
			<line id="Line_1" y1="18" x2="18" transform="translate(21.5 3.5)" fill="none" stroke="#fff" strokeWidth="2"/>
			<line id="Line_2" x2="18" y2="18" transform="translate(21.5 3.5)" fill="none" stroke="#fff" strokeWidth="2"/>
		</g>
	</svg>
	);
};

export default CrossIcon;
