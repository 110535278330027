import React from 'react';
import { usePage } from '../../hooks/usePage';
import { StreamUsePageData } from '../../types/invitee';

const Header: React.FC = () => {
	const { event } = usePage<StreamUsePageData>();


	return (
		<header>
			{event.logo && (
				<>
					<div className='branding-container'>
						<div className="branding">
							<img src={event.logo.original} alt="" />
						</div>
					</div>
				</>
			)}
		</header>
	);
};

export default Header;
