export function usePage<T>(): T {
	/** @ts-ignore */
	return {
		"replylive": {
			"host": "tecc2021.com",
			"port": "80",
			"pusher_key": "fake",
			"driver": "pusher",
			"force_tls": true
		},
		"opentok": {"api_key": ""},
		"errors": {},
		"translations": {},
		"intercom": {"app_id": null},
		"event": {
			"name": "TEST Top Employers 2021 Certification Celebration",
			"slug": "fake-slug",
			"label": "test event",
			"summary": null,
			"start_date": "2021-01-27T00:00:00.000000Z",
			"end_date": "2021-01-27T00:00:00.000000Z",
			"settings": {
				"private_chat": true,
				"redirect_url": null,
				"open_registration": true,
				"profile_in_header": false,
				"allow_cancellation": false,
				"enable_stream_page": true,
				"helpdesk_in_header": false,
				"show_profile_block": true,
				"show_sign_up_block": true,
				"force_sign_up_popup": false,
				"google_maps_api_key": null,
				"google_analytics_key": null,
				"private_chat_in_header": false,
				"max_invitee_connections": "0",
				"notifications_in_header": false,
				"hide_mobile_menu_in_header": false,
				"force_redirect_to_stream_page": false
			},
			"logo": {
				"thumb": "/storage/replylive/top-employers-1605600570OqfQC.svg",
				"small": "/storage/replylive/top-employers-1605600570OqfQC.svg",
				"medium": "/storage/replylive/top-employers-1605600570OqfQC.svg",
				"large": "/storage/replylive/top-employers-1605600570OqfQC.svg",
				"original": "/storage/replylive/top-employers-1605600570OqfQC.svg"
			},
			"header_image": {
				"thumb": "/storage/replylive/e-2-header-1607534434syFn2-thumb.png",
				"small": "/storage/replylive/e-2-header-1607534434syFn2-small.png",
				"medium": "/storage/replylive/e-2-header-1607534434syFn2-medium.png",
				"large": "/storage/replylive/e-2-header-1607534434syFn2-large.png",
				"original": "/storage/replylive/e-2-header-1607534434syFn2.png"
			},
			"key_visual": {
				"thumb": "/storage/replylive/bg-temp-1607530500HumgY-thumb.jpg",
				"small": "/storage/replylive/bg-temp-1607530500HumgY-small.jpg",
				"medium": "/storage/replylive/bg-temp-1607530500HumgY-medium.jpg",
				"large": "/storage/replylive/bg-temp-1607530500HumgY-large.jpg",
				"original": "/storage/replylive/bg-temp-1607530500HumgY.jpg"
			},
			"has_footer": false,
			"footer_logo_left": null,
			"footer_logo_right": null
		},
		"video_url": "https://vimeo.com/522737987/911b1e40b6",
		"invitee": {
			"id": 16990,
			"firstname": "John",
			"lastname": "Doe",
			"token": "fake-token",
			"name": "John Doe",
			"avatar": null,
			"email": "john.doe@fxagency.nl",
			"phonenumber": null,
			"info": {"function": null, "agree_policy": false, "extra": []},
			"attending_status": "accepted",
			"muted": false,
			"is_moderator": false,
			"has_joined_virtual_studio": false,
			"groups": [],
			"online_count": 0
		},
		"video_answers": {
			// "drink": "mocktail",
			// "route": "historical",
			// "outfit": "blue-suit",
			// "opening": "clicked answer",
			// "transport": "train"
		},
		"video_questions": [{
			"id": 1,
			"type": "columns",
			"name": "Opening",
			"key": "opening",
			"ranges": [{"start": 167, "end": 422}],
			"options": [{
				"id": "6e037437de0da304",
				"target": 464,
				"value": "clicked answer",
				"description": "Click to answer the call"
			}],
			"onNoAnswer": 0
		}, {
			"id": 2,
			"type": "columns",
			"name": "Choice 1 Train or Plane",
			"key": "transport",
			"ranges": [{"start": 1398, "end": 1718}],
			"options": [{
				"id": "10cfb12107d8f7b7",
				"target": 2483,
				"value": "plane",
				"description": "Plane"
			}, {"id": "1b3e677103ac8476", "target": 1759, "value": "train", "description": "Train"}],
			"onNoAnswer": 1
		}, {
			"id": 3,
			"type": "columns",
			"name": "Choice 2 Car Route",
			"key": "route",
			"ranges": [{"start": 4021, "end": 4291}],
			"options": [{
				"id": "6de60ccaa24472a0",
				"target": 6588,
				"value": "scenic",
				"description": "Scenic route"
			}, {"id": "51df7d1a6088cdb0", "target": 4332, "value": "historical", "description": "Historic route"}],
			"onNoAnswer": null
		}, {
			"id": 4,
			"type": "columns",
			"name": "Choice 3 Outfit",
			"key": "outfit",
			"ranges": [{"start": 6093, "end": 6545}, {"start": 8532, "end": 8991}],
			"options": [{
				"id": "740c5f0b30aa0d5f",
				"target": 9032,
				"value": "blue-dress",
				"description": "Outfit&nbsp;1"
			}, {
				"id": "39fb08d360b5fa17",
				"target": 9705,
				"value": "black-suit",
				"description": "Outfit&nbsp;2"
			}, {
				"id": "22efd3f85ee5f0fb",
				"target": 10388,
				"value": "red-dress",
				"description": "Outfit&nbsp;3"
			}, {"id": "dd43678a4434af73", "target": 11061, "value": "blue-suit", "description": "Outfit&nbsp;4"}],
			"onNoAnswer": 3
		}, {
			"id": 5,
			"type": "avatar-select",
			"name": "Choice 4 Avatar",
			"key": "avatar",
			"ranges": [{"start": 12918, "end": 13129}],
			"options": [{"id": "cf6110ad8a06ef58", "target": 13170, "value": "selected", "description": "select"}],
			"onNoAnswer": null
		}, {
			"id": 6,
			"type": "columns",
			"name": "Choice 5 Drink",
			"key": "drink",
			"ranges": [{"start": 13593, "end": 13821}],
			"options": [{
				"id": "d7652a7c53cb418b",
				"target": 15021,
				"value": "champagne",
				"description": "Champagne"
			}, {"id": "674e0c1ca1304816", "target": 13862, "value": "mocktail", "description": "Mocktail"}],
			"onNoAnswer": 1
		}, {
			"id": 7,
			"type": "end",
			"name": "Video End",
			"key": "end",
			"ranges": [{"start": 14939, "end": 14979}, {"start": 16063, "end": 16103}],
			"options": [{
				"id": "e9acd584f1791364",
				"target": 99999999,
				"value": "hard end",
				"description": "hard end"
			}],
			"onNoAnswer": null
		}, {
			"id": 8,
			"type": "jump-timestamp",
			"name": "Jump from Plane to next scene",
			"key": "after-train-jump",
			"ranges": [{"start": 2420, "end": 2460}],
			"options": [{
				"id": "4e4636ba0a6cb459",
				"target": 3167,
				"value": "arrival amsterdam",
				"description": ""
			}],
			"onNoAnswer": 0
		}, {
			"id": 9,
			"type": "jump-timestamp",
			"name": "Jump from Clothing to next scene",
			"key": "clothing_jump",
			"ranges": [{"start": 9645, "end": 9685}, {"start": 10328, "end": 10368}, {
				"start": 11001,
				"end": 11041
			}, {"start": 11664, "end": 11704}],
			"options": [{
				"id": "f26fe28a6d1e8adb",
				"target": 11724,
				"value": "dressing_done",
				"description": "dressing done scene"
			}],
			"onNoAnswer": null
		}],
		"activities": [{
			"id": 28,
			"location_id": 4,
			"name": "Opening",
			"start": "2021-01-27T11:00:00.000000Z",
			"end": "2021-01-27T12:00:00.000000Z",
			"summary": "You will be welcomed by David Plink, CEO of Top Employers Institute, who will open the event. There will be some surprise appearances, so make sure you’re there!",
			"description": null,
			"image": {
				"thumb": "/storage/replylive/Program/01-entrance-experience-1607511341Szc3e-thumb.png",
				"small": "/storage/replylive/Program/01-entrance-experience-1607511341Szc3e-small.png",
				"medium": "/storage/replylive/Program/01-entrance-experience-1607511341Szc3e-medium.png",
				"large": "/storage/replylive/Program/01-entrance-experience-1607511341Szc3e-large.png",
				"original": "/storage/replylive/Program/01-entrance-experience-1607511341Szc3e.png"
			},
			"plenary": true,
			"subscribable": false,
			"token": "fake-token-1",
			"attending_status": null,
			"speaker_ids": [],
			"breakouts": [],
			"breakouts_in_registration": false
		}, {
			"id": 29,
			"location_id": 4,
			"name": "Virtual award handovers",
			"start": "2021-01-27T13:00:00.000000Z",
			"end": "2021-01-27T14:00:00.000000Z",
			"summary": "This is the moment you have all been waiting for! It’s your time to shine. Your award will be presented in a virtual handover.",
			"description": null,
			"image": {
				"thumb": "/storage/replylive/Program/02-opening-1607511346sw8pq-thumb.png",
				"small": "/storage/replylive/Program/02-opening-1607511346sw8pq-small.png",
				"medium": "/storage/replylive/Program/02-opening-1607511346sw8pq-medium.png",
				"large": "/storage/replylive/Program/02-opening-1607511346sw8pq-large.png",
				"original": "/storage/replylive/Program/02-opening-1607511346sw8pq.png"
			},
			"plenary": true,
			"subscribable": false,
			"token": "fake-token-2",
			"attending_status": null,
			"speaker_ids": [],
			"breakouts": [],
			"breakouts_in_registration": false
		}],
		"location": {
			"id": 4,
			"sort_order": 0,
			"name": "test stream location",
			"description": null,
			"address": null,
			"contact_text": null,
			"show_on_map": false,
			"position": {"lat": 0, "lng": 0},
			"marker": null,
			"image": null,
			"online_location": true,
			"online_location_url": null,
			"stream_url": "",
			"invitees_token": "fake-token-3",
			"sidebar_image": null,
			"wall_image": null,
			"stream_background": null,
			"program_offset": 0,
			"show_breakouts": true,
			"has_private_chat": null,
			"private_chat_group_only": null,
			"video_width": 0,
			"video_height": 0,
			"show_networking": false
		}
	};
}