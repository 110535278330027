export function urlToFile(url: string, filename: string, mimeType: string = 'text/plain'): Promise<File>
{
	return (fetch(url)
		.then(function(res){return res.arrayBuffer();})
		.then(function(buf){return new File([buf], filename,{type:mimeType});})
	);
}

export function urlToImage(url: string, filename: string): Promise<File>
{
	return urlToFile(url, filename, 'image/png');
}

export function fileToBase64(file: File | Blob): Promise<string>
{
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = error => reject(error);
	});
}
