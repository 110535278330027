import React, { useState, createContext } from 'react';
import { OpeningPageData, VideoAnswers, VideoQuestion } from '../types/opening';
import { usePage } from '../hooks/usePage';

export interface InteractivePlayerContextInterface {
	answers: VideoAnswers;
	setAnswers: React.Dispatch<React.SetStateAction<VideoAnswers>>;
	playing: boolean;
	setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
	activeQuestion: VideoQuestion | undefined;
	setActiveQuestion: React.Dispatch<React.SetStateAction<VideoQuestion>>;
	loading: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InteractivePlayerContext = createContext<InteractivePlayerContextInterface>(null);

const InteractivePlayerContextContainer: React.FC = (props) => {
	const { video_answers } = usePage<OpeningPageData>();
	const [answers, setAnswers] = useState<VideoAnswers>(video_answers);
	const [playing, setPlaying] = useState<boolean>(false);
	const [activeQuestion, setActiveQuestion] = useState<VideoQuestion | undefined>();
	const [loading, setLoading] = useState<boolean>(false);

	return (
		<InteractivePlayerContext.Provider
			value={{
				answers,
				setAnswers,
				playing,
				setPlaying,
				activeQuestion,
				setActiveQuestion,
				loading,
				setLoading,
			}}
		>
			{props.children}
		</InteractivePlayerContext.Provider>
	);
};

export default InteractivePlayerContextContainer;
