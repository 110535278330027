import React, { ChangeEvent, KeyboardEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ActiveQuestionProps } from '../../types/opening';
import Modal from '../eventsite/Modal';
import WebcamCapture from '../stream/Chat/ImagePicker/WebcamCapture';
import { urlToImage } from '../../helpers/file-helpers';
import { InteractivePlayerContext, InteractivePlayerContextInterface } from '../../contexts/InteractivePlayerContext';
import AvatarCrop from '../eventsite/AvatarCrop';
import {AvatarContext, AvatarContextInterface} from "../../contexts/AvatarContext";

type AvatarCropModalProps = {
	image: File;
	onClose?: () => any;
	onSubmit?: (image: Blob) => any;
};

const OpeningCropModal: React.FC<AvatarCropModalProps> = ({ image, onClose, onSubmit }) => {
	const handleOnClose = useCallback(() => {
		if (onClose) {
			onClose();
		}
	}, [onClose]);

	const handleOnSubmit = useCallback(
		(image: Blob) => {
			if (onSubmit) {
				onSubmit(image);
			}
		},
		[onSubmit],
	);

	return (
		<Modal onClose={handleOnClose} modalClass="video-crop-modal">
			<div className="modal-avatar">
				<AvatarCrop image={image} onSubmit={handleOnSubmit} />
			</div>
		</Modal>
	);
};

const AvatarSelectOption: React.FC<ActiveQuestionProps> = ({ onSubmit, activeQuestion, setPlayState }) => {
	const {avatar, setAvatar} = useContext<AvatarContextInterface>(AvatarContext);
	const inputRef = useRef<HTMLInputElement>();
	const [image, setImage] = useState<File>(null);
	const [useCapture, setUseCapture] = useState<boolean>(false);
	const { loading, setLoading } = useContext<InteractivePlayerContextInterface>(InteractivePlayerContext);

	useEffect(() => {
		setLoading(false);
	}, [avatar, setLoading]);

	const onLabelKey = (event: KeyboardEvent<HTMLLabelElement>) => {
		if (event.key === ' ' || event.key === 'Enter') {
			onInputClick();
			inputRef.current.click();
		}
	};

	const onInputClick = () => {
		setPlayState(false);
	};

	const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (!files[0] || loading) {
			return;
		}
		setImage(files[0]);
	};

	const onCaptureClick = () => {
		setPlayState(false);
		setUseCapture(true);
	};

	const onWebcamChange = async (base64: string = null) => {
		if (!base64) {
			return;
		}
		const file = await urlToImage(base64, 'avatar.png');
		setImage(file);
		setUseCapture(false);
	};

	const onContinue = () => {
		if (!avatar) {
			return;
		}
		setPlayState(true);
		onSubmit(activeQuestion, activeQuestion.options[0]);
	};

	const onCropCancel = () => {
		setImage(null);
		inputRef.current.value = null;
	};

	const onImageSubmit = async (imageBlob: Blob) => {
		// send image to server
		setLoading(true);
		setImage(null);

		setTimeout(() => {
			const reader = new FileReader();
			reader.onloadend = () => {
				setAvatar(reader.result as string);
			};
			reader.readAsDataURL(imageBlob);
		}, 500);
	};

	return (
		<div className="avatar-option">
			<div className="avatar-buttons">
				<button className="btn" onClick={onCaptureClick}>
					Take picture
				</button>
				<label className="btn" tabIndex={0} onClick={onInputClick} onKeyUp={onLabelKey} onKeyPress={onLabelKey}>
					<input type="file" name="avatar" onChange={onInputChange} ref={inputRef} />
					Upload picture
				</label>
				{avatar && (
					<button className="btn btn-gold" onClick={onContinue}>
						Continue
					</button>
				)}
			</div>
			{useCapture && (
				<Modal onClose={() => setUseCapture(false)} modalClass="webcam-modal">
					<WebcamCapture onChange={onWebcamChange} onCancel={() => setUseCapture(false)} />
				</Modal>
			)}
			{image && <OpeningCropModal image={image} onSubmit={onImageSubmit} onClose={onCropCancel} />}
		</div>
	);
};

export default AvatarSelectOption;
