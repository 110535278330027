import { InviteePrivateType } from '../types/invitee';
import { VideoAnswers, VideoQuestion, VideoQuestionOption } from '../types/opening';

export async function submitVideoQuestion(
	invitee: InviteePrivateType,
	question: VideoQuestion,
	answer: VideoQuestionOption,
): Promise<VideoAnswers | null> {
	try {
		// note should be a merge object of all answers
		return {
			value: answer.value,
		};
	} catch (error) {
		console.log('api error', error.message, error.code);
		return null;
	}
}
