import React, { ReactElement, ReactNodeArray } from 'react';

type IconButtonProps = {
	children: ReactElement | ReactNodeArray | null | undefined;
	className?: string;
	onClick?: () => any;
}

const IconButton: React.FC<IconButtonProps> = ({children, className, onClick}) => {
	className = className ? `icon-button ${className}` : 'icon-button';

	return (
		<button className={className} onClick={onClick} type='button'>
			{children}
		</button>
	);
};

export default IconButton;
