import React from 'react';
import InteractivePlayer from './components/opening/InteractivePlayer';
import Header from './components/stream/Header';
import Footer from './components/stream/Footer';
import InteractivePlayerContextContainer from './contexts/InteractivePlayerContext';
import OpeningAside from './components/opening/OpeningAside';
import AvatarContextContainer from "./contexts/AvatarContext";

const Index: React.FC = () => {

	return (
		<AvatarContextContainer>
			<InteractivePlayerContextContainer>
				<div id="stream-page" className="opening-experience">
					<div className="background"/>
					<main>
						<Header/>
						<div className="stream-content">
							<div className="video-container">
								<div className="video-player-wrapper">
									<InteractivePlayer/>
								</div>
							</div>
						</div>
						<Footer programOffset={0}/>
					</main>
					<OpeningAside/>
				</div>
			</InteractivePlayerContextContainer>
		</AvatarContextContainer>
	);
};

export default Index;
