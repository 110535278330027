import React, { useContext, useEffect, useState } from 'react';
import { InteractivePlayerContext, InteractivePlayerContextInterface } from '../../contexts/InteractivePlayerContext';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {AvatarContext, AvatarContextInterface} from "../../contexts/AvatarContext";

const bodyImages = {
	'blue-dress': 'blue-dress.png',
	'blue-suit': 'blue-suit.png',
	'red-dress': 'red-dress.png',
	'black-suit': 'black-suit.png',
};
const drinkImages = {
	mocktail: 'mocktail.png',
	champagne: 'champagne.png',
};
const imageBase = '/images/opening/';

const OpeningAside: React.FC = () => {
	const { answers, playing, loading } = useContext<InteractivePlayerContextInterface>(InteractivePlayerContext);
	const [started, setStarted] = useState<boolean>(false);
	const {avatar} = useContext<AvatarContextInterface>(AvatarContext);

	const bodyImage = bodyImages[answers?.outfit] || 'naked.png';
	const drinkImage = drinkImages[answers?.drink] || null;

	useEffect(() => {
		if (playing && !started) {
			setStarted(true);
		}
	}, [playing, started]);

	return (
		<aside id="sidebar" className="expanded">
			{started ? (
				<>
					<h3>Your avatar</h3>
					<div className="character-preview">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 334.307 334.307">
							<path
								d="M167.153,334.307a168.386,168.386,0,0,1-33.687-3.4A166.241,166.241,0,0,1,73.7,305.759a167.642,167.642,0,0,1-60.56-73.542A166.3,166.3,0,0,1,3.4,200.841a168.783,168.783,0,0,1,0-67.374A166.236,166.236,0,0,1,28.547,73.7,167.639,167.639,0,0,1,102.09,13.136,166.3,166.3,0,0,1,133.466,3.4a168.781,168.781,0,0,1,67.374,0,166.241,166.241,0,0,1,59.77,25.151,167.643,167.643,0,0,1,60.561,73.543,166.324,166.324,0,0,1,9.74,31.376,168.779,168.779,0,0,1,0,67.374,166.233,166.233,0,0,1-25.151,59.77,167.642,167.642,0,0,1-73.543,60.561,166.305,166.305,0,0,1-31.377,9.74A168.387,168.387,0,0,1,167.153,334.307Zm0-253.22a85.665,85.665,0,0,0-48.12,14.7A86.325,86.325,0,0,0,87.85,133.653a85.95,85.95,0,0,0,7.936,81.621,86.315,86.315,0,0,0,37.867,31.182,85.953,85.953,0,0,0,81.621-7.935,86.323,86.323,0,0,0,31.183-37.867,85.951,85.951,0,0,0-7.936-81.621A86.321,86.321,0,0,0,200.654,87.85,85.529,85.529,0,0,0,167.153,81.087Z"
								fill="#cb8f37"
							/>
						</svg>

						<img alt="" src={`${imageBase}head.png`} className="group" />
						<img alt="" src={`${imageBase}${bodyImage}`} className="group" />
						{drinkImage && <img alt="" src={`${imageBase}${drinkImage}`} className="group" />}
						<div className="avatar preview">
							<div className="center">
								{avatar && <LazyLoadImage src={avatar} alt="Avatar" />}
								<div className="avatar-edit">{loading && <span className="loader" />}</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					<h1>
						Welcome to the
						<br />
						Top Employers 2021
						<br />
						Certification Celebration
					</h1>
					<p>Join all other Top Employers in this virtual venue. Click the button, and start your journey.</p>
				</>
			)}
		</aside>
	);
};

export default OpeningAside;
