import React, { useCallback, useEffect, useRef, useState } from 'react';
import Croppie from 'croppie';

type AvatarCropProps = {
	image: File;
	onSubmit: (image: Blob) => any;
};

const AvatarCrop: React.FC<AvatarCropProps> = ({ image, onSubmit }) => {
	const imageSrc = URL.createObjectURL(image);
	const croppieElement = useRef();
	const [croppie, setCroppie] = useState(null);

	useEffect(() => {
		if (!image || !!croppie) return;

		const newCroppie = new Croppie(croppieElement.current, {
			enableExif: true,
			enableOrientation: true,
			viewport: {
				type: 'circle',
				width: 180,
				height: 180,
			},
		});

		const croppieTimeout = setTimeout(() => {
			newCroppie.setZoom(0);
		}, 60);

		setCroppie(newCroppie);
		return () => {
			if (croppie) {
				clearTimeout(croppieTimeout);
				croppie.destroy();
			}
		};
	}, [image, croppie]);

	const handleSubmit = useCallback(() => {
		croppie.result('blob').then((blob: Blob) => {
			onSubmit(blob);
		});
	}, [croppie, onSubmit]);

	return (
		<div className="croppie upload-preview">
			<div className="croppie-wrapper" style={{ height: '400px' }}>
				<img src={imageSrc} ref={croppieElement} alt="avatar-preview" />
				<button
					className="rotate rotate-left"
					onClick={() => {
						if (croppie) {
							croppie.rotate(90);
						}
					}}
				/>
				<button
					className="rotate rotate-right"
					onClick={() => {
						if (croppie) {
							croppie.rotate(-90);
						}
					}}
				/>
			</div>
			<button className="btn btn-primary" onClick={handleSubmit}>
				Save
			</button>
		</div>
	);
};

export default AvatarCrop;
