import React, { useEffect } from 'react';
import { ActiveQuestionProps } from '../../types/opening';

const VideoEndOverlay: React.FC<ActiveQuestionProps> = ({ setPlayState }) => {
	useEffect(() => {
		setPlayState(false);
	}, [setPlayState]);

	const onClick = () => {
		window.location.reload();
	};

	return (
		<div className="option">
			<button onClick={onClick} className="btn btn-gold">
				Enter the venue
			</button>
		</div>
	);
};

export default VideoEndOverlay;
