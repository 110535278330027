import { ActivityType, EventType, InviteePrivateType, LocationType } from './invitee';

export enum VideoQuestionType {
	COLUMNS = 'columns',
	ROWS = 'rows',
	JUMP_TIMESTAMP = 'jump-timestamp',
	AVATAR_SELECT = 'avatar-select',
	END = 'end',
}

export type VideoQuestionOption = {
	id: string;
	target?: number; // video timestamp --  or -> just continue
	description?: string;
	value: string;
};

export type VideoTimeRange = {
	start: number; // video timestamp
	end: number; // video timestamp
};

export type VideoQuestion = {
	id: number;
	name: string;
	key: string;
	ranges: VideoTimeRange[];
	type: VideoQuestionType;
	options: VideoQuestionOption[];
	onNoAnswer?: number; // index or none -> random --
};

export type VideoAnswers = {
	[key: string]: string;
};

export type ActiveQuestionProps = {
	onSubmit: (activeQuestion: VideoQuestion, option: VideoQuestionOption) => void;
	activeQuestion: VideoQuestion;
	setPlayState: (state: boolean) => any;
};

export type OpeningPageData = {
	event: EventType;
	invitee: InviteePrivateType;
	video_questions: VideoQuestion[];
	video_answers: VideoAnswers;
	video_url: 'string';
	activities: ActivityType[];
	location?: LocationType;
};

// type StudioInvitee = {
// 	id: number;
// 	firstname: string;
// 	lastname: string;
// 	name: string;
// 	avatar: string; // https://url.to.file/image.jpg
// 	attending_status: string;
// 	muted: boolean;
// 	is_moderator: boolean;
// 	has_joined_virtual_studio: boolean;
// 	groups: { id: number }[]; // array of objects with "id" property
// 	online_count: number;
// 	company: {
// 		id: number;
// 		token: string; // is unique so can also be used as identifier if preferred
// 		name: string;
// 		country?: string; // optional (can be empty)
// 		continent?: string; // optional
// 		logo: string; // https://url.to.file/image.jpg
// 	};
// 	customization: {
// 		outfit: 'blue-dress' | 'red-dress' | 'blue-suit' | 'black-suit'; // either one of these strings
// 		drink: 'champagne' | 'mocktail'; // either one of these strings
// 	};
// };
