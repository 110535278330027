import React from 'react';
import Timeline from './Timeline';
import { useMediaPredicate } from 'react-media-hook';

const Footer: React.FC<{ programOffset: number }> = ({ programOffset }) => {
	const largeScreen = useMediaPredicate('(min-width: 992px)');

	return (
		<footer>
			{largeScreen && <Timeline programOffset={programOffset} />}
		</footer>
	);
};

export default Footer;
