const config = {
	appRoot: document.getElementById('app'),
	modalRoot: document.getElementById('modal'),
	content: {
		contentLibrary: {
			DEFAULT_MAX_LENGTH: 6,
		},
	},
	croppieConfig: {
		type: 'blob',
		size: {
			width: 640,
			height: 640,
		},
		circle: false,
		format: 'png',
		quality: 1,
	},
};

export const { appRoot, modalRoot, croppieConfig } = config;
export default config;
