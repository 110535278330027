import { VideoQuestionOption, VideoQuestion, VideoAnswers } from '../types/opening';
import ReactPlayer from 'react-player';

export function getActiveQuestion(currentTime: number, questions: VideoQuestion[]): VideoQuestion | undefined {
	return questions.find(
		(question) =>
			!!question.ranges.find(
				(moment) => framesToSeconds(moment.start) <= currentTime && framesToSeconds(moment.end) > currentTime,
			),
	);
}

export function shouldAnswerForInvitee(
	answers: VideoAnswers,
	active?: VideoQuestion,
	newActive?: VideoQuestion,
): boolean {
	const endOfActiveChoice = active && newActive?.id !== active.id;

	return endOfActiveChoice && !answers[active.key];
}

export function getOptionForInvitee(videoQuestion: VideoQuestion, previousAnswers: VideoAnswers): VideoQuestionOption {
	const { onNoAnswer, options, key } = videoQuestion;

	if (previousAnswers[key]) {
		const previousOption = options.find((option) => option.value === previousAnswers[key]);
		if (previousOption) {
			return previousOption;
		}
	}

	if (options[onNoAnswer]) {
		return options[onNoAnswer];
	}
	return options[Math.floor(Math.random() * options.length)];
}

export function goToOptionTarget(option: VideoQuestionOption, player: ReactPlayer): void {
	if (option.target) {
		player.seekTo(framesToSeconds(option.target), 'seconds');
	}
}

export function framesToSeconds(frames: number, fps: number = 25): number {
	return frames / fps;
}
