import React, { useCallback, useState, useRef } from 'react';
import Webcam from 'react-webcam';

import IconButton from '../../../styled/IconButton';
import CameraIcon from '../../../styled/icons/CameraIcon';
import CrossIcon from '../../../styled/icons/CrossIcon';

type WebcamInputProps = {
	onChange: (imageSrc: string) => any;
	onCancel: () => any;
};

const WebcamCapture: React.FC<WebcamInputProps> = ({ onChange, onCancel }) => {
	const [loading, setLoading] = useState<boolean>(true);
	const webcamRef = useRef(null);
	const capture = useCallback(() => {
		const imageSrc = webcamRef.current.getScreenshot();
		onChange(imageSrc);
	}, [webcamRef, onChange]);

	return (
		<div className="webcam-capture">
			<Webcam
				audio={false}
				ref={webcamRef}
				screenshotFormat="image/jpeg"
				videoConstraints={{
					facingMode: 'user',
					width: 320,
					height: 180,
				}}
				onUserMedia={() => setLoading(false)}
			/>
			{loading && (
				<svg className='spinner' version="1.1" width="100" height="100" id="L9" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
					<path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
						<animateTransform
							attributeName="transform"
							attributeType="XML"
							type="rotate"
							dur="1s"
							from="0 50 50"
							to="360 50 50"
							repeatCount="indefinite" />
					</path>
				</svg>
			)}
			<div className="camera-button">
				<IconButton className='capture-image' onClick={capture}>
					<CameraIcon />
				</IconButton>
			</div>

			<div className='cancel-button'>
				<IconButton className='cancel-image' onClick={onCancel}>
					<CrossIcon />
				</IconButton>
			</div>
		</div>
	);
};

export default WebcamCapture;
