import React from 'react';
import { ActiveQuestionProps, VideoQuestionType } from '../../types/opening';
import AvatarSelectOption from './AvatarSelectOption';
import VideoEndOverlay from './VideoEndOverlay';

const QuestionCenter: React.FC<ActiveQuestionProps> = ({ onSubmit, activeQuestion, setPlayState }) => {
	const { type, options } = activeQuestion;
	switch (type) {
		case VideoQuestionType.AVATAR_SELECT:
			return (
				<AvatarSelectOption onSubmit={onSubmit} activeQuestion={activeQuestion} setPlayState={setPlayState} />
			);
		case VideoQuestionType.END:
			return <VideoEndOverlay onSubmit={onSubmit} activeQuestion={activeQuestion} setPlayState={setPlayState} />;
		default:
			return (
				<>
					{options.map((option) => (
						<button key={option.id} onClick={() => onSubmit(activeQuestion, option)} className="option">
							<span
								className={`btn ${activeQuestion.type}`}
								dangerouslySetInnerHTML={{ __html: option.description }}
							/>
						</button>
					))}
				</>
			);
	}
};

const ActiveQuestion: React.FC<ActiveQuestionProps> = ({ onSubmit, activeQuestion, setPlayState }) => {
	return (
		<div className={`overlay question ${activeQuestion.type}`}>
			<QuestionCenter onSubmit={onSubmit} setPlayState={setPlayState} activeQuestion={activeQuestion} />
		</div>
	);
};

export default ActiveQuestion;
